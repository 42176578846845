import { usePostHog } from 'posthog-js/react';
import { useTranslation } from 'react-i18next';
import { setQuery } from '../../state/actions/searchParams';
import { useSearchStore } from '../../state/searchStore';
import { POSTHOG_EVENT } from '@/services/posthog/events';
import { Icons } from '@/assets';
import { useUserDataStore } from '@/services/firebase/state/userDataStore';

type Props = {
  onSearch: () => void;
};

export const ExamplePrompts = (props: Props) => {
  const { onSearch } = props;
  const { t } = useTranslation();
  const posthog = usePostHog();
  const { data } = useUserDataStore();

  const activeDataRole = useSearchStore((s) => s.currentParams.dataRole);
  const searchWithPrompt = (prompt: string) => {
    posthog.capture(POSTHOG_EVENT.SEARCH_EXAMPLE_SELECT, { prompt });
    setQuery(prompt);
    onSearch();
  };

  const examplePrompts =
    data?.storedUser.dataRoles.find((role) => role.roleName === activeDataRole)?.examplePrompts ??
    [];

  return (
    <div className="flex flex-col items-start gap-3 mt-10 text-xs  animate-fade-in">
      <p className=" text-qura-neutral-light">{t('searchPromptPage.subtitle')}</p>
      {Array.isArray(examplePrompts) &&
        examplePrompts.map((prompt, index) => {
          return (
            <button
              key={index}
              onClick={() => searchWithPrompt(prompt)}
              className="flex bg-white px-2 py-3 shadow-md hover:bg-qura-neutral-ghost rounded-md gap-2">
              <Icons.ArrowTiltedUpRight className=" text-qura-neutral-light" />
              <p className="text-qura-neutral-jet text-left font-medium">{prompt}</p>
            </button>
          );
        })}
    </div>
  );
};
