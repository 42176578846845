import { getAuth } from 'firebase/auth';
import { doc, updateDoc } from 'firebase/firestore';
import { db } from '../firebaseConfig';

export interface UserSettings {
  language: 'en' | 'sv';
  hasSeenQuickGuide: boolean;
}

export const setUserSettings = async (settings: Partial<UserSettings>) => {
  const authUser = getAuth().currentUser;
  const userId = authUser?.uid;
  if (!userId) return;

  const usersEditableRef = doc(db, 'usersEditable', userId);
  await updateDoc(usersEditableRef, settings);
};
