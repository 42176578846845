import { useTranslation } from 'react-i18next';
import { images } from '@/assets';
import { getQuickGuideUrl, mailto } from '@/constants';

type OnboardingModalProps = {
  setHasSeenQuickGuid: () => void;
};

export const OnboardingModal = (props: OnboardingModalProps) => {
  const { t, i18n } = useTranslation();

  return (
    <div className="flex gap-7 flex-col mt-7 max-w-full">
      <div className="gradient-box flex relative flex-col border-solid rounded-large shadow-modal-light w-[820px] max-w-full justify-center bg-white px-12 overflow-clip">
        <img
          src={images.loginBlob}
          className="absolute aspect-square min-w-[950px] w-[950px] self-center bottom-0 z-0 filter grayscale translate-x-[-560px] translate-y-[480px] rotate-[78deg]"
        />
        <img
          src={images.loginBlob}
          className="absolute aspect-square min-w-[950px] w-[950px] self-center bottom-0 z-0 filter grayscale translate-x-[560px] translate-y-[-90px] rotate-[78deg]"
        />
        <div
          className={`flex gap-7 relative flex-col  items-stretch text-center py-[100px] max-w-[520px] self-center justify-center`}>
          <div className="flex justify-center">
            <p className="text-light py-1.5 px-3 text-xs rounded-full bg-qura-neutral-ghost">
              {t('onboardingPage.welcomeMessage')}
            </p>
          </div>
          <h1 className="font-inter font-normal text-[32px] text-qura-neutral-jet-medium">
            {t('onboardingPage.title')}
          </h1>
          <p className="text-qura-neutral-balanced max-w-[430px] self-center">
            <span className="text-qura-neutral-jet">{t('onboardingPage.learnHowToUseQura')}</span>{' '}
            {t('onboardingPage.description')}
          </p>
          <div className="flex gap-4 justify-center mt-[32px]">
            <a href={getQuickGuideUrl(i18n.language)} target="_blank" rel="noopener noreferrer">
              <button
                onClick={() => {
                  props.setHasSeenQuickGuid();
                }}
                className={`px-7 group rounded-full p-[3px] bg-qura-neutral-jet shadow-auth-submit `}>
                <p className="text-white py-[6px] ">{t('onboardingPage.viewQuickGuide')}</p>
              </button>
            </a>
            <button
              onClick={() => {
                props.setHasSeenQuickGuid();
              }}
              className={`px-7 group rounded-full p-[3px] bg-white shadow-auth-submit `}>
              <p className="text-black rounded-full py-[6px]">{t('onboardingPage.showMeLater')}</p>
            </button>
          </div>
        </div>
      </div>
      <div className="flex items-center justify-center">
        <div className="font-inter text-xs font-medium flex items-center justify-center">
          <p className="pr-1">{t('onboardingPage.haveMoreQuestions')}</p>
          <a className="text-qura-neutral-balanced font-light underline" href={`mailto:${mailto}`}>
            {t('onboardingPage.contactTeam')}
          </a>
        </div>
      </div>
    </div>
  );
};
