import { getAuthToken } from '@/services/firebase/functions/getCurrentUser';
import { useQuery } from '@tanstack/react-query';
import { z } from 'zod';
import { RESOURCE_API_URL } from '../constants';
import axios from 'axios';
import { standardAxiosOptions } from '../utils';

const relatedDocSchema = z.object({
  document_id: z.string(),
  legal_id: z.string(),
  title: z.string(),
  score: z.number(),
});

const relationResponseSchema = z.object({
  cited_docs: z.array(relatedDocSchema),
  cited_by_docs: z.array(relatedDocSchema),
});

export type RelationResponse = z.infer<typeof relationResponseSchema>;
export type RelatedDoc = z.infer<typeof relatedDocSchema>;

const getRelations = async (query: string, documentId: string, limit: number): Promise<RelationResponse> => {
  console.log('getRelations', query, documentId, limit);

  const token = await getAuthToken();
  const response = await axios.post<RelationResponse>(
    `${RESOURCE_API_URL}/database/document/${documentId}/relations`,
    {
      query,
      limit,
    },
    {
      responseType: 'json',
      ...standardAxiosOptions(token),
    },
  );

  return relationResponseSchema.parse(response.data);
};

export const useRelations = (
  query: string,
  documentId: string,
  limit: number,
) => {
  return useQuery({
    queryKey: ['relations', query, documentId, limit],
    queryFn: () => getRelations(query, documentId, limit),
  });
};
