/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { ExamplePrompts } from './ExamplePrompts';
import { OnboardingModal } from './OnboardingModal';
import { DataRoleToggle } from '../../components/DataRoleToggle/DataRoleToggle';
import Feedback from '../../components/Feedback/Feedback';
import { HelpButton } from '../../components/HelpButton/HelpButton';
import { SearchBar } from '../../components/SearchBar/SearchBar';
import UserDropDown from '../../components/UserDropDown/UserDropDown';
import { createNewSearch } from '../../state/actions/createNewSearch';
import { setQuery } from '../../state/actions/searchParams';
import { useSearchStore } from '../../state/searchStore';
import { sendSearchParams } from '../../state/actions/sendSearchParams';
import { searchIsActive } from '../../state/hooks/useIsSearchActive';
import SearchHistoryModal from '@/features/Search/components/SearchHistoryModal/SearchHistoryModal.tsx';
import { Icons } from '@/assets';
import { setUserSettings } from '@/services/firebase/functions/setUserSettings';
import { useUserDataStore } from '@/services/firebase/state/userDataStore';
import { alertError } from '@/utils/alertError';

export const SearchPromptPage = () => {
  const [searchId, setSearchId] = useState<string | null>(null);
  const navigate = useNavigate();
  const selectedDataRole = useSearchStore((s) => s.currentParams.dataRole);
  const [isSearchHistoryModalOpened, setSearchHistoryModalOpened] = useState(false);
  const hasSeenQuickGuide = useUserDataStore((s) => s.data?.storedUser.hasSeenQuickGuide);
  const isInitialized = useUserDataStore((s) => s.isInitialized);
  const showQuickGuide = !hasSeenQuickGuide && isInitialized;

  useEffect(() => {
    if (!selectedDataRole) return;
    const queryFromParams = new URLSearchParams(window.location.search).get('query');
    if (queryFromParams) setQuery(queryFromParams);

    createNewSearch()
      .then(({ searchId }) => {
        setSearchId(searchId);
        if (queryFromParams) {
          sendSearchParams(searchId);
          navigate(`/search/${searchId}`);
        }
      })
      .catch(() => {});
  }, [selectedDataRole]);

  const setHasSeenQuickGuid = () => {
    useUserDataStore.setState(
      (state) => {
        if (state.data) {
          state.data.storedUser.hasSeenQuickGuide = true;
        }
      },
      false,
      'users/update-settings',
    );

    setUserSettings({ hasSeenQuickGuide: true }).catch((err) => {
      alertError(err);
    });
  };

  const handleSearch = (searchId: string) => {
    sendSearchParams(searchId);
    navigate(`/search/${searchId}`);
  };

  const onSearch = () => {
    if (searchId && searchIsActive(searchId)) {
      handleSearch(searchId);
      return;
    }

    createNewSearch()
      .then(({ searchId }) => {
        handleSearch(searchId);
      })
      .catch(() => {});
  };

  const selectDocument = async (documentId: string) => {
    navigate(`/document/${documentId}`);
  };

  const handleSearchHistoryModalStatus = () => {
    setSearchHistoryModalOpened(!isSearchHistoryModalOpened);
  };

  return (
    <>
      <div className="flex flex-col justify-center items-center h-screen w-screen">
        <Icons.LogoText className="h-5" />
        <div className="h-2" />
        {showQuickGuide ? (
          <OnboardingModal setHasSeenQuickGuid={setHasSeenQuickGuid} />
        ) : (
          <>
            <DataRoleToggle />
            <div className="h-5" />
            <div className="flex flex-col  px-2 w-[1200px] max-w-[100%]">
              <SearchBar
                noTextShine
                onSearch={onSearch}
                disabled={false}
                selectDocument={selectDocument}
                onOpenHistoryModal={handleSearchHistoryModalStatus}
              />
              <div className="px-4 min-h-64">
                {isInitialized && <ExamplePrompts onSearch={onSearch} />}
              </div>
            </div>
          </>
        )}
      </div>
      <div className="fixed right-10 top-10 z-30">
        <UserDropDown />
      </div>
      {isSearchHistoryModalOpened && (
        <SearchHistoryModal onClose={handleSearchHistoryModalStatus} onSearch={onSearch} />
      )}
      <Feedback />
      {!showQuickGuide && <HelpButton />}
      <ToastContainer className="pointer-events-auto" />
    </>
  );
};
