import { ZodSchema, z } from 'zod';
import { scoreAdjustment } from './utils';
import { convertSnakeToCamelCaseObject } from '@/utils/snakeToCamelCase';

// ================ SEARCH SCHEMAS ================
const searchFilterSchema = z
  .object({
    tag_ids: z.array(z.string()).default([]),
    after_date: z
      .tuple([z.number().int(), z.number().int(), z.number().int()])
      .nullable()
      .default(null),
    before_date: z
      .tuple([z.number().int(), z.number().int(), z.number().int()])
      .nullable()
      .default(null),
    document_ids: z.array(z.string()).default([]),
  })
  .transform(convertSnakeToCamelCaseObject);

const searchModeSchema = z
  .object({
    no_chunks: z.boolean().default(false),
    no_ai_filter: z.boolean().default(false),
  })
  .transform(convertSnakeToCamelCaseObject);

export const searchParamsSchema = z
  .object({
    query: z.string(),
    max_documents: z.number().int().default(25),
    max_chunks_per_document: z.number().int().default(5),
    filter: searchFilterSchema,
    search_mode: searchModeSchema,
    data_role: z.string().nullable().default(null),
  })
  .transform(convertSnakeToCamelCaseObject);

export const stageSchema = z.record(
  z.string(),
  z.object({
    weight: z.number().int(),
    status: z.enum(['pending', 'ongoing', 'completed', 'error']),
  }),
);

export const searchProgressSchema = z
  .object({
    complete: z.boolean(),
    stages: stageSchema,
    searched_documents: z.number().int().nullable().default(0),
    discovered_chunks: z.number().int().nullable().default(0),
    relevant_chunks: z.number().int().nullable().default(0),
    relevant_documents: z.number().int().nullable().default(0),
    search_score: z.number().nullable().default(null),
    misc: z.record(z.any()).nullable().default({}),
  })
  .transform(convertSnakeToCamelCaseObject)
  .transform((data) => {
    data.searchScore = scoreAdjustment(data.searchScore ?? 0);
    return data;
  });

// ================ RESULT SCHEMAS ================

const rectSchema = z
  .object({
    x0: z.number(),
    y0: z.number(),
    x1: z.number(),
    y1: z.number(),
  })
  .transform(convertSnakeToCamelCaseObject);

const pagePropsSchema = z
  .object({
    page_number: z.number().int(),
    highlights: z.array(
      z.object({
        rect: rectSchema,
        color: z.tuple([z.number(), z.number(), z.number()]),
      }),
    ),
    cropping: rectSchema.nullable(),
    width: z.number(),
    height: z.number(),
  })
  .transform(convertSnakeToCamelCaseObject);

const filePropsSchema = z
  .object({
    page_selection: z.array(z.number()).nullable(),
    page_overrides: z.array(pagePropsSchema).nullable(),
  })
  .transform(convertSnakeToCamelCaseObject);
export type FileProps = z.infer<typeof filePropsSchema>;

export const resultChunkSchema = z
  .object({
    chunk_id: z.string(),
    document_id: z.string(),
    page_id: z.string(),
    document_unit_id: z.string(),
    page_number: z.number().int(),
    excerpt: filePropsSchema,
    excerpt_width: z.number(),
    excerpt_height: z.number(),
    scale_factor: z.number().default(1.2),
    relevance_score: z.number(),
    height_offset: z.number(),
    highlight_color: z.tuple([z.number(), z.number(), z.number()]).nullable().default(null),
    flags: z.array(z.string()).default([]),
    misc: z.record(z.any()).default({}),
  })
  .transform(convertSnakeToCamelCaseObject)
  .transform((data) => {
    data.relevanceScore = scoreAdjustment(data.relevanceScore);
    return data;
  });

export const resultDocumentSchema = z
  .object({
    document_id: z.string(),
    document_unit_id: z.string(),
    legal_id: z.string().nullable().default(null),
    relevance_score: z.number(),
    page_url: z.string().nullable().default(null),
    collected_at: z.number().int(),
    file_props: filePropsSchema,
    title: z.string(),
    tag_labels: z.array(z.string()),
    tag_ids: z.array(z.string()),
    year: z.number().int().nullable().default(null),
    month: z.number().int().nullable().default(null),
    day: z.number().int().nullable().default(null),
    language: z.string(),
    page_count: z.number().int(),
    output_chunks: z.array(resultChunkSchema),
    flags: z.array(z.string()).default([]),
    misc: z.record(z.any()).default({}),
  })
  .transform(convertSnakeToCamelCaseObject)
  .transform((data) => {
    data.relevanceScore = scoreAdjustment(data.relevanceScore);
    return data;
  });

const pagePropertiesSchema = z
  .object({
    height: z.number(),
    width: z.number(),
    page_number: z.number(),
  })
  .transform(convertSnakeToCamelCaseObject);

export const documentPropertiesSchema = z
  .object({
    scale_factor: z.number().default(1.2),
    file_size: z.number(),
    page_count: z.number(),
    document_unit_id: z.string(),
    page_properties: z.array(pagePropertiesSchema),
    legal_id: z.string().nullable().default(null),
    title: z.string(),
    year: z.number().int().nullable().default(null),
    month: z.number().int().nullable().default(null),
    day: z.number().int().nullable().default(null),
    language: z.string().nullable().default(null),
    document_status: z.string().nullable().default(null),
  })
  .transform(convertSnakeToCamelCaseObject);

// ================ FILTER SCHEMAS ================
export type TagNode = {
  tagId: string;
  label: string;
  description: string | null;
  weight: number;
  subNodes: TagNode[];
};

export const tagNodeSchema = z
  .object({
    tag_id: z.string(),
    label: z.string(),
    description: z.string().nullable().default(null),
    weight: z.number().int(),
    sub_nodes: z
      .lazy(() => tagNodeSchema.array())
      .nullable()
      .default([]),
  })
  .transform(convertSnakeToCamelCaseObject) as unknown as z.ZodType<TagNode>;

export const availableFiltersSchema = z
  .object({
    tag_nodes: tagNodeSchema.array(),
  })
  .transform(convertSnakeToCamelCaseObject);

export const availableDateRangeSchema = z
  .object({
    after_date: z.tuple([z.number().int(), z.number().int(), z.number().int()]),
    before_date: z.tuple([z.number().int(), z.number().int(), z.number().int()]),
  })
  .transform(convertSnakeToCamelCaseObject);

// ================ WEBSOCKET SCHEMAS ================
const createOutputSchema = <T extends string, C extends z.ZodTypeAny>(
  contentType: T,
  content: C,
) => {
  return z.object({
    search_id: z.string(),
    trace_id: z.string(),
    action_ids: z.array(z.string()),
    content_type: z.literal(contentType),
    content: content,
  });
};

const searchIdOutputSchema = createOutputSchema('search_id', z.any()).transform(
  convertSnakeToCamelCaseObject,
);

export const searchResultSchema = z
  .object({
    ingredients: z.array(z.string()),
    documents: z.array(resultDocumentSchema),
    progress: searchProgressSchema,
    search_action: searchParamsSchema.optional(),
    filter_selection: searchFilterSchema.optional().nullable().default(null),
  })
  .transform(convertSnakeToCamelCaseObject);
const searchResultOutputSchema = createOutputSchema('result', searchResultSchema).transform(
  convertSnakeToCamelCaseObject,
);
const searchShutdownOutputSchema = createOutputSchema('shutdown', z.any()).transform(
  convertSnakeToCamelCaseObject,
);
const searchErrorOutputSchema = createOutputSchema(
  'error',
  z
    .object({
      code: z.string().optional(),
      error: z.record(z.any()),
    })
    .transform(convertSnakeToCamelCaseObject),
).transform(convertSnakeToCamelCaseObject);

export const webSocketOutputSchema = z.union([
  searchIdOutputSchema,
  searchErrorOutputSchema,
  searchResultOutputSchema,
  searchShutdownOutputSchema,
]);

// ================ Database info SCHEMAS ================

export type DataBaseInfoSource = {
  document_count: number;
  weight: number;
  label: string;
  start_date: number[];
  end_date: number[];
  sub_sources: DataBaseInfoSource[] | null;
};

export const dataBaseInfoSourceSchema: ZodSchema<DataBaseInfoSource> = z.object({
  document_count: z.number(),
  weight: z.number(),
  label: z.string(),
  start_date: z.array(z.number()),
  end_date: z.array(z.number()),
  sub_sources: z.lazy(() => dataBaseInfoSourceSchema.array()).nullable(),
});

export const databaseInfoResponseSchema = z.record(
  z.string(),
  z.object({
    sources: z.array(dataBaseInfoSourceSchema),
  }),
);
