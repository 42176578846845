import { useEffect } from 'react';
import {
  DocumentData,
  DocumentReference,
  doc,
  getDoc,
  onSnapshot,
  setDoc,
} from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import { useUserDataStore } from './services/firebase/state/userDataStore';
import { db } from './services/firebase/firebaseConfig';
import { alertError } from './utils/alertError';

const RELOAD_INTERVAL = 3 * 60 * 60 * 1000; // 3 hours in milliseconds

export const CheckForceReload = () => {
  const { data } = useUserDataStore();

  const checkReload = async (docRef: DocumentReference<DocumentData>) => {
    const auth = getAuth();
    if (!auth.currentUser) return;
    const data = (await getDoc(docRef)).data();
    if (!data?.shouldReload) return;
    await reload(docRef);
  };

  const reload = async (docRef: DocumentReference<DocumentData>) => {
    const auth = getAuth();
    await setDoc(docRef, { shouldReload: false, lastReload: new Date() }, { merge: true });
    await auth.currentUser?.getIdToken(true);
    await auth.currentUser?.reload();
    window.location.reload();
  };

  useEffect(() => {
    if (!data) return;
    const docRef = doc(db, 'usersEditable', data.authUser.uid);

    const reloadTimer = setTimeout(() => {
      reload(docRef);
    }, RELOAD_INTERVAL);

    const unsubscribe = onSnapshot(
      docRef,
      () => {
        checkReload(docRef)
          .then(() => {
            clearTimeout(reloadTimer);
          })
          .catch((err) => {
            alertError(err);
          });
      },
      (err: unknown) => {
        clearTimeout(reloadTimer);
        alertError(err);
      },
    );

    return () => unsubscribe();
  }, [data]);

  return null;
};
